<template>
  <el-dialog
    append-to-body
    :title="title"
    :visible.sync="dialogFormVisible"
    width="50%"
    @close="close"
  >
    <el-form ref="form" :model="form" :rules="rules">
      <el-form-item label="自定义行程内容" prop="content">
        <el-input maxlength="10" style="width: 30%" v-model="form.content" />
      </el-form-item>
      <el-form-item label="开始时间" label-width="120px" prop="startTime">
        <el-date-picker
          v-model="form.startTime"
          format="yyyy-MM-dd HH:mm:ss"
          value-format="timestamp"
          type="datetime"
          placeholder="请选择日期和时间"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="结束时间" label-width="120px" prop="endTime">
        <el-date-picker
          v-model="form.endTime"
          format="yyyy-MM-dd HH:mm:ss"
          value-format="timestamp"
          type="datetime"
          placeholder="请选择日期和时间"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="行程地点" label-width="120px" prop="travelLocation">
        <el-input style="width: 30%" v-model="form.travelLocation" />
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </template>
  </el-dialog>
</template>
    
    <script>
import { genggai } from "@/api/userManagement";

export default {
  name: "TableEdit",
  data() {
    return {
      options1: [
        {
          id: "1",
          creClasName: "启用",
        },
        {
          id: "0",
          creClasName: "锁定",
        },
      ], //状态
      actorScore: "", //当前状态
      actorScorewo: "", //修改后的分数分数
      form: {
        participateId: "", //当前项的id
      },
      rules: {
        content: [
          {
            required: true,
            message: "请输入自定义行程",
            trigger: "blur",
            type: "string",
          },
        ],
        startTime: [
          {
            required: true,
            message: "请选择状态",
            trigger: "change",
          },
        ],
        endTime: [
          {
            required: true,
            message: "请选择状态",
            trigger: "change",
          },
        ],
        travelLocation: [
          {
            required: true,
            message: "请输入行程地点",
            trigger: "blur",
          },
        ],
      },
      title: "",
      dialogFormVisible: false,
    };
  },
  created() {},
  methods: {
    handleChange() {
      this.show = false;
      setTimeout(() => {
        this.show = true;
      }, 300);
    },
    async optosall(e) {
      this.form.isactivity = e.id;
    }, //修改状态
    showEdit(row) {
      this.form.participateId = row;
      console.log(this.form.participateId);
      this.title = "添加自定义行程";
      this.dialogFormVisible = true;
    },
    close() {
      this.$refs["form"].resetFields();
      this.form = this.$options.data().form;
      this.dialogFormVisible = false;
    },
    save() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          this.form.startTime = this.form.startTime / 1000;
          this.form.endTime = this.form.endTime / 1000;

          const { msg } = await genggai(this.form);

          if (msg === "添加成功") {
            this.$message({
              message: "添加成功",
              type: "success",
            });
            this.$emit("fetch-data");
            this.close();
          }
        }
      });
    },
  },
};
</script>
    