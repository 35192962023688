<template>
  <el-dialog
    append-to-body
    :title="title"
    :visible.sync="dialogFormVisible"
    width="40%"
    @close="close"
  >
    <el-form ref="form" :model="form" :rules="rules">
      <!-- 状态  -1取消 0审核中 1审核通过 2暂不确定 -2嘉宾拒绝 -3删除嘉宾 3确认参加 -9暂存 -->
      <el-form-item label="当前审核状态为">
        <span v-if="zhuangtai == -1">取消</span>
        <span v-else-if="zhuangtai == 0">审核中</span>
        <span v-else-if="zhuangtai == 1">审核通过</span>
        <span v-else-if="zhuangtai == 2">暂不确定 </span>
        <span v-else-if="zhuangtai == -2">嘉宾拒绝 </span>
        <span v-else-if="zhuangtai == -3">删除嘉宾 </span>
        <span v-else-if="zhuangtai == 3">确认参加 </span>
        <span v-else-if="zhuangtai == -9">暂存</span>
        <span v-else-if="zhuangtai == 11">审核不通过</span>
      </el-form-item>
      <el-form-item>
        <div v-for="(item, index) in plateList">{{ index + 1 }}、 {{ item.title }}</div>
      </el-form-item>

      <el-form-item label="选择状态" prop="status">
        <el-select v-model="form.status" clearable placeholder="请选择审核状态">
          <el-option
            v-for="item in options1"
            :key="item.id"
            :label="item.creClasName"
            :value="item.id"
            @click.native="optosall(item)"
          />
        </el-select>
      </el-form-item>
      <el-form-item v-if="form.status == 11" label="驳回原因" prop="reason">
        <el-input
          v-model="form.reason"
          placeholder="请输入驳回原因"
          style="width: 80%"
        />
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </template>
  </el-dialog>
</template>
  
  <script>
import { shenhe } from "@/api/userManagement";

export default {
  name: "Editshenhe",
  data() {
    return {
      plateList:[],
      zhuangtai: "",
      options1: [
        {
          id: 1,
          creClasName: "通过",
        },
        {
          id: 11,
          creClasName: "不通过",
        },
      ], //状态
      form: {
        pid: "",
        id: "",
        status: "",
      },
      rules: {
        status: [
          {
            required: true,
            message: "请选择状态",
            trigger: "change",
            type: "number",
          },
        ],
        reason: [
          {
            required: true,
            message: "请输入驳回原因",
            trigger: "blur",
          },
        ],
      },
      title: "",
      dialogFormVisible: false,
    };
  },
  created() {},
  methods: {
    handleChange() {
      this.show = false;
      setTimeout(() => {
        this.show = true;
      }, 300);
    },
    async optosall(e) {
      console.log(e);
    }, //修改状态
    showEdit(row) {
      this.form.pid = row.id;
      this.form.id = row.id;
      console.log(row.id);
      this.title = "更改审核状态";
      this.dialogFormVisible = true;
      this.zhuangtai = row.status;
      console.log(row.plateList)
      this.plateList = row.plateList;
    },
    close() {
      this.$refs["form"].resetFields();
      this.form = this.$options.data().form;
      this.dialogFormVisible = false;
    },
    save() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          const res = await shenhe(this.form);
          console.log(res);
          if (res.code == 1) {
            this.$alert(res.msg, {
              dangerouslyUseHTMLString: true,
              type: "success",
            }).then(() => {
              this.$emit("fetch-data");
              this.close();
            });
          } else {
            this.$alert(res.msg, {
              dangerouslyUseHTMLString: true,
              type: "warning",
            });
          }
        }
      });
    },
  },
};
</script>
  