import request from "@/common/utils/request";
import Vue from "vue";

export function doDelete(data) {
  return request({
    url: Vue.prototype.$sadsdasBaseUrl + "/adminapi/content/get-article-list",
    method: "post",
    data
  });
}

export function getList(data) {
  return request({
    url: Vue.prototype.$sadsdasBaseUrl + "/adminapi/content/add-article",
    method: "post",
    data
  });
}
export function getxingchengList(params) {
  //获取自定义行程列表
  return request({
    url: Vue.prototype.$baseUrl + "/adminapi/definejourney/list",
    method: "get",
    params
  });
}
export function genggai(data) {
  //++自定义行程列表
  return request({
    url: Vue.prototype.$baseUrl + "/adminapi/definejourney/save",
    method: "post",
    data
  });
}
export function updata(data) {
  //修改自定义行程列表
  return request({
    url: Vue.prototype.$baseUrl + "/adminapi/definejourney/update",
    method: "post",
    data
  });
}
export function deleteUser(data) {
  //--自定义行程列表
  return request({
    url: Vue.prototype.$baseUrl + "/adminapi/definejourney/delete",
    method: "post",
    data
  });
}
export function updateArticle(data) {
  return request({
    url: Vue.prototype.$sadsdasBaseUrl + "/adminapi/content/update-article",
    method: "post",
    data
  });
}

export function deleteArticle(data) {
  return request({
    url: Vue.prototype.$sadsdasBaseUrl + "/adminapi/content/delete-article",
    method: "post",
    data
  });
}

export function getAllSubjectList() {
  return request({
    url:
      Vue.prototype.$sadsdasBaseUrl + "/adminapi/content/get-all-subject-list",
    method: "post"
  });
}
export function shenhe(data) {
  return request({
    url: Vue.prototype.$adminApiUrl + "/participate/shenhe",
    method: "post",
    data
  });
}
