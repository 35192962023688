<!-- 报名管理 -->
<template>
  <el-dialog
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :destory-on-close="true"
    :modal-append-to-body="false"
    :title="title"
    :visible.sync="dialogFormVisible"
    width="65%"
    @close="close"
  >
    <el-form :inline="true" :model="queryForm" @submit.native.prevent>
      <el-form-item>
        <el-button type="primary" @click="baoming(queryForm.jobId)">
          添加
        </el-button>
      </el-form-item>

      <!-- <el-form-item>
          <el-input
            v-model.trim="queryForm.keyWord"
            clearable
            placeholder="请输入用户名"
          />
        </el-form-item>
        <el-form-item>
          <el-button icon="el-icon-search" type="primary" @click="queryData">
            查询
          </el-button>
        </el-form-item> -->
    </el-form>

    <el-table
      ref="multipleTable"
      v-loading="listLoading"
      border
      :data="list"
      @selection-change="handleSelectionChange"
    >
      <el-table-column align="center" show-overflow-tooltip type="selection" />
      <el-table-column align="center" label="序号" width="55">
        <template #default="{ $index }">
          {{ $index + 1 }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="自定义行程内容"
        prop="content"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="行程地点"
        prop="travelLocation"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="开始时间"
        :formatter="dateFormat"
        prop="startTime"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="结束时间"
        :formatter="dateFormat"
        prop="endTime"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="操作"
        show-overflow-tooltip
        width="130"
        fixed="right"
      >
        <template #default="{ row }">
          <el-button type="text" @click="handleEdittwo(row)">编辑</el-button>
          <el-button type="text" @click="handleEdit(row)">删除</el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <!-- <el-pagination
        background
        :current-page="queryForm.pageNum"
        :layout="layout"
        :page-size="queryForm.pageSize"
        :total="total"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      /> -->
    <Edittwo ref="Edittwo" @fetch-data="choose" />
    <Edit ref="Edit" @fetch-data="choose" />

    <!-- <el-button class="baocun" size="medium" type="primary" @click="save">
          保存
        </el-button> -->
  </el-dialog>

  <!-- 增加 -->
</template>
    
    <script>
/* eslint-disable*/
import { getxingchengList, deleteUser, jilu } from "@/api/userManagement";
// import { roleId } from "@/api/yanyuan"; //引入角色内容
import Edittwo from "./addxingchengtwo.vue";
import Edit from "./edit.vue";
import util from "@/common/utils/util.js";
//import { number } from 'echarts'
export default {
  name: "search",
  components: { Edittwo, Edit },

  data() {
    const validatesurfacePlot = (rule, value, callback) => {
      console.log(this.form.surfacePlot);
      if (this.form.surfacePlot === "") {
        callback(new Error("请添加图片!!!!"));
      } else {
        callback();
      }
    };
    return {
      listLoading: true,
      options1: [
        {
          id: 0,
          creClasName: "未审核",
        },
        {
          id: 1,
          creClasName: "审核通过",
        },
        {
          id: 2,
          creClasName: "审核未通过",
        },
      ],
      layout: "total, sizes, prev, pager, next, jumper",
      downloadLoading: false,
      total: 0,
      multipleSelection: [],
      options2: [],
      queryForm: {
        jobId: "", //赋值当前项的id
      },
      list: [],
      token: "",
      imageUrl: "",
      options3: [],
      form: {
        value: "",
        roleId: "",

        // areaId: '',
        // articleContent: '',
        // sort: 0,
        // roles: [],
        // displayLocked: true,
        // recommendLocked: true,
        // share: true, //是否分享
        // locked: false,
        // surfacePlot: '', //图片
      },
      activated() {
        if (this.$refs.MaskVideo.src) this.$refs.MaskVideo.play();
      },
      deactivated() {
        if (this.$refs.MaskVideo.src) this.$refs.MaskVideo.pause();
      },

      rules: {
        title: [{ required: true, trigger: "blur", message: "请输入文章名称" }],
        surfacePlot: [
          { required: true, trigger: "change", message: "请添加图片" },
          { validator: validatesurfacePlot, trigger: "change" },
        ],
        userId: [
          { required: true, trigger: "blur", message: "请选择所属用户" },
        ],
        sort: [{ required: true, trigger: "blur", message: "排序不能为空" }],
      },
      title: "",
      dialogFormVisible: false,
    };
  },
  created() {
    this.token = this.$store.getters["user/token"];
    // this.fetchDatatwo();
  },
  methods: {
    //时间格式化
    dateFormat(row, column) {
      var date = row[column.property];
      console.log(date);
      if (date == undefined) {
        return "";
      }
      return this.timestampToTime(date);
    },
    handleEdit(row) {
      let loadingDiv;
      this.$confirm("是否确认删除资源:" + row.content, "删除确认")
        .then((res) => {
          console.log(res);
          if ((res = "confirm")) {
            loadingDiv = util.showLoading("删除中...");
            return deleteUser({ id: row.id });
          }
        })
        .then((res) => {
          loadingDiv.close();
          if (res.code == 0) {
            this.$alert(res.msg, "删除失败", {
              dangerouslyUseHTMLString: true,
              type: "warning",
            });
          } else if (res.code == 1) {
            this.$alert(res.msg, "删除成功", {
              dangerouslyUseHTMLString: true,
              type: "success",
            }).then((res) => {
              this.choose();
            });
          }
        })
        .catch((res) => {
          if (loadingDiv) {
            loadingDiv.close();
          }
        });
      //   this.$confirm("你确定要删除当前项吗", null, async () => {
      //     console.log("weqweqwe");
      //     const { msg } = await deleteUser({ id: row.id });
      //     console.log(msg);
      //     if (msg === "删除成功")
      //       this.$message({
      //         message: "删除成功",
      //         type: "success",
      //       });
      //     await this.choose();
      //   });
    },
    timestampToTime(timestamp) {
      var date = new Date(timestamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      let Y = date.getFullYear() + "-";
      let M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      let D = date.getDate() + " ";
      let h = date.getHours() + ":";
      let m = date.getMinutes() + ":";
      let s = date.getSeconds();
      return Y + M + D + h + m + s;
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          return v[j];
        })
      );
    },

    formatJsontwo(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },
    baoming(row) {
      if (row) {
        this.$refs["Edittwo"].showEdit(row);
      } else {
        this.$refs["Edittwo"].showEdit();
      }
    },
    handleEdittwo(row) {
      if (row.id) {
        this.$refs["Edit"].showEdit(row);
      } else {
        this.$refs["Edit"].showEdit();
      }
    },
    async chooseone(e) {
      this.listLoading = true;
      this.queryForm.pageNum = 1;
      const res = await getbaoList(this.queryForm);
      this.list = res.data.dataList;
      this.total = res.data.total;
      this.listLoading = false;
    },
    async optosall(e) {
      this.queryForm.ischecked = this.form.value;
      this.listLoading = true;
      this.queryForm.pageNum = 1;
      const res = await getbaoList(this.queryForm);
      this.list = res.data.dataList;
      this.total = res.data.total;
      this.listLoading = false;
    },
    async choose(e) {
      this.listLoading = true;
      //   this.queryForm.pageNum = 1;
      const res = await getxingchengList({
        participateId: this.queryForm.jobId,
      });
      console.log("sadsasaddsa", res);
      this.list = res.data;
      this.total = res.data.total;
      this.listLoading = false;
    },
    handleCurrentChange(val) {
      this.queryForm.pageNum = val;
      this.showEdit();
    },
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.showEdit();
    },
    setSelectRows(val) {
      this.selectRows = val;
    },
    // handleChange(a) {
    //   this.form.articleContent = a
    // },
    handleAvatarSuccess(res, file, fileList) {
      this.imageUrl = URL.createObjectURL(file.raw);

      this.form.surfacePlot = res.data;
    },

    async queryData() {
      this.listLoading = true;
      this.queryForm.pageNum = 1;
      const res = await getbaoList(this.queryForm);
      this.list = res.data.dataList;
      this.total = res.data.total;
      this.listLoading = false;
    },
    async showEdit(row) {
      this.queryForm.jobId = row.id;

      this.title = "自定义行程列表";
      this.choose();
      this.dialogFormVisible = true;
    },
    close() {
      let userId = this.form.userId;
      // this.$refs['form'].resetFields()
      this.form = this.$options.data().form;
      if (!this.form.id) {
        this.form.userId = userId;
      }
      this.dialogFormVisible = false;
      this.imageUrl = "";
      // this.form = ''
    },
    async fetchDatatwo() {
      let queryForm = {};
      const res = await roleId(queryForm);
      this.options2 = res.data;
      console.log(res);
    },
    async fetchData() {
      const restwo = await getxingchengList({
        participateId: this.queryForm.jobId,
      });
      this.options3 = restwo.data;
    },
    nickNameChange() {
      this.$forceUpdate();
    },
  },
};
</script>
    <style>
.avatar-uploader .el-upload {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  width: 178px;
  height: 178px;
  font-size: 28px;
  line-height: 178px;
  color: #8c939d;
  text-align: center;
}
.avatar {
  display: block;
  width: 178px;
  height: 178px;
}
.baocun {
  width: 100%;
  height: 100%;
}
</style>
    