<template>
  <el-dialog
    append-to-body
    :title="title"
    :visible.sync="dialogFormVisible"
    width="30%"
    @close="close"
  >
    <el-form ref="form" :model="form" :rules="rules">
      <el-form-item prop="content">
        <div v-html="parsePlate(rows, statusall)"></div>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>
          
          <script>
import { updata } from "@/api/userManagement";
import util from "@/common/utils/util.js";

export default {
  name: "TableEdit",
  data() {
    return {
      options1: [
        {
          id: "1",
          creClasName: "启用",
        },
        {
          id: "0",
          creClasName: "锁定",
        },
      ], //状态
      actorScore: "", //当前状态
      actorScorewo: "", //修改后的分数分数
      form: {
        participateId: "", //当前项的id
      },
      rules: {
        content: [
          {
            required: true,
            message: "请输入自定义行程",
            trigger: "blur",
            type: "string",
          },
        ],
        startTime: [
          {
            required: true,
            message: "请选择状态",
            trigger: "change",
          },
        ],
        endTime: [
          {
            required: true,
            message: "请选择状态",
            trigger: "change",
          },
        ],
        travelLocation: [
          {
            required: true,
            message: "请输入行程地点",
            trigger: "blur",
          },
        ],
      },
      title: "",
      dialogFormVisible: false,
      rows: "",
      statusall: "",
    };
  },
  created() {},
  methods: {
    handleChange() {
      this.show = false;
      setTimeout(() => {
        this.show = true;
      }, 300);
    },
    async optosall(e) {
      this.form.isactivity = e.id;
    }, //修改状态
    showEdit(row, status) {
      this.form = Object.assign({}, row);
      if (status == 1) {
        this.title = "查看已确认板块";
      } else {
        this.title = "查看待确认板块";
      }
      this.dialogFormVisible = true;
      this.rows = row;
      console.log(row.plateList);
      this.statusall = status;
    },
    parsePlate(row, status) {
      if (util.isNotEmpty(row.plateList)) {
        let str = "";
        let index = 1;
        let plateList = row.plateList;
        for (let i in plateList) {
          if (util.isEmpty(plateList[i])) {
            continue;
          }

          str +=
            "<div class='ellipsis'>" +
            index +
            ".<" +
            plateList[i].title +
            "></div>";
          index++;
        }

        if (status == 1) {
          if (row.status == 1 || row.status == 3) {
            return str;
          }
        } else if (status == 0) {
          if (row.status == 2) {
            return str;
          }
        }
      } else {
        return "";
      }
    },
    close() {
      this.$refs["form"].resetFields();
      this.form = this.$options.data().form;
      this.dialogFormVisible = false;
    },
    save() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          this.form.startTime = this.form.startTime / 1000;
          this.form.endTime = this.form.endTime / 1000;

          const { msg } = await updata(this.form);
          console.log(msg);
          if (msg === "修改成功") {
            this.$message({
              message: "修改成功",
              type: "success",
            });
            this.$emit("fetch-data");
            this.dialogFormVisible = false;
          }
        }
      });
    },
  },
};
</script>
          